<script lang="ts" setup>
const title = ref('Ralph Controls')
const description = ref('Sign in')

useHead({
  title,
  meta: [
    {
      name: title,
      content: description,
    },
  ],
});
</script>

<template>
  <NuxtLoadingIndicator />

  <slot />
  
  <UNotifications />
</template>
